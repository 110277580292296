var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"car-confirmation"},[_c('Header',{attrs:{"isHeader":true,"back":true,"step":_vm.currentStepIndex}}),_c('Wrapper',{attrs:{"ismain":true},scopedSlots:_vm._u([{key:"sidebar",fn:function(){return [_c('Sidebar')]},proxy:true},{key:"footer",fn:function(){return [_c('Footer',[_c('Button',{staticClass:"btn-primary align-btn-tertiary",attrs:{"id":"getCorrectToken","label":_vm.translateString('continue')},on:{"action":function($event){return _vm.next('getCorrectToken')}}}),_c('Button',{staticClass:"btn-secondary elm-desktop",attrs:{"label":_vm.translateString('changePlate')},on:{"action":function($event){return _vm.changePlate()}}})],1)]},proxy:true}])},[_c('Title',{attrs:{"titleStyle":"title","text":_vm.translateString('vehicleWithService')}}),_c('CarInfo',{attrs:{"justMobile":true}}),_c('div',{staticClass:"checkbox-container"},_vm._l(([
          ..._vm.markingService,
          { id: 'combo-4', label: 'Agendar Novo serviço' },
        ]),function(item,index){return _c('CheckboxContainer',{key:index,attrs:{"id":'service-' + item.id,"label":item.id === 'combo-4'
            ? item.label
            : _vm.translateString('serviceDate') +
              ': ' +
              item.serviceDate +
              ' | ' +
              '  ' +
              _vm.translateString('serviceHour') +
              ': ' +
              item.serviceHour +
              ' | ' +
              '  ' +
              _vm.translateString('dealerName') +
              ': ' +
              item.dealerName,"checkboxClass":"checkbox-round","containerClass":"right","inputType":"radio","name":"service"},on:{"updateInput":function($event){return _vm.updateInput($event, item.id === 'combo-4' ? 4 : item.dealerId, item)}}})}),1),(_vm.carCombo.comboSelectedByUser)?_c('p',{staticClass:"sm-text-italic"},[_vm._v(" "+_vm._s(_vm.carCombo.comboLabel)+" ")]):_vm._e(),_c('div',{staticClass:"row flex-wrap align-btn-mobile align-container-btn mb-2"},[_c('Button',{staticClass:"btn-tertiary elm-mobile",attrs:{"label":_vm.translateString('changePlate')},on:{"action":function($event){return _vm.changePlate()}}})],1)],1),_c('Modal',{attrs:{"msg":_vm.callError,"isActive":_vm.callError != ''}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }